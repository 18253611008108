(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/capitalize-first-letter/assets/javascripts/capitalize-first-letter.js') >= 0) return;  svs.modules.push('/components/lb-utils/capitalize-first-letter/assets/javascripts/capitalize-first-letter.js');

'use strict';

const capitalizeFirstLetter = text => {
  const capitalizedText = text ? text.charAt(0).toUpperCase() + text.slice(1) : '';
  return capitalizedText;
};
if (svs.isServer) {
  module.exports = capitalizeFirstLetter;
} else {
  setGlobal('svs.components.lbUtils.capitalizeFirstLetter.capitalizeFirstLetter', capitalizeFirstLetter);
}

 })(window);